@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

@keyframes shineEffect {
  0% {
    background-position: 150% 150%;
  }
  100% {
    background-position: -50% 0%;
  }
}

@keyframes bgColorChange {
  100% {
    background-color: rgba(92, 160, 167, 1);
  }
}

@keyframes bgColorChangeUpdated {
  100% {
    background-color: #db4f4f;
  }
}

@keyframes flash {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-left: rem(15px);
}

.title {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: -0.32px;
  margin-top: rem(10px);
  margin-bottom: rem(10px);
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.formRow {
  display: flex;
}

.textLeft {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: -0.32px;
  max-width: rem(150px);
  width: 100%;
  margin-top: rem(8px);
}

.btnContainer {
  display: flex;
  gap: rem(10px);
  align-items: center;
  justify-content: center;
}

.catalogBtnContainer {
  display: flex;
  background-color: #fff;
  width: rem(220px);
  height: rem(42px);
  padding: rem(11px) rem(29px);
  border-radius: 70px;
  border: 1px solid rgba(92, 160, 167, 0.2);
  gap: rem(10px);
  align-items: center;
  justify-content: flex-start;
}

.btn {
  display: flex;
  width: rem(142px);
  height: rem(32px);
  padding: rem(11px) rem(29px);
  border-radius: 70px;
  border: none;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  color: #fff;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(24px);
  letter-spacing: 1.6px;
  &.disabled,
  &:disabled {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
    pointer-events: none;
  }

  position: relative;
  background-color: #338891;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 200% 200%;
  }

  &:hover {
    animation: bgColorChange 0.1s 0.4s forwards;
  }

  &:hover::before {
    background-image: linear-gradient(
      225deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.7) 50%,
      rgba(255, 255, 255, 0.7) 52%,
      rgba(255, 255, 255, 0) 52%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: shineEffect 0.6s ease-in forwards;
  }
}

.alt {
  border: 1px solid #338891;
  background: #fff;
  color: #338891;
}

.aiDreamContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 34px;
  max-width: rem(680px);
  margin-bottom: 34px;
}

.aiDreamHeadlineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.aiTitle {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: -0.32px;
  min-width: 124px;
}

.aiDescription {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24px);
  letter-spacing: -0.32px;
  margin-left: 23px;
}

.aiDreamContentContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
  padding: 23px;
  flex-shrink: 0;
  margin-left: 124px;
  border-radius: 30px;
  background-color: #fff;
  cursor: pointer;
}

.aiDreamCampaignContainer {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
}

.aiDreamHeadlines {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(24px);
  letter-spacing: -0.32px;
}
.aiDreamText {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24px);
  letter-spacing: -0.32px;
}

.objectiveList {
  padding-left: 10px;
}

.objectiveList li::before {
  content: '\2022';
  color: #338891;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.buttonContainer {
  height: rem(32px);
  max-width: 158px;
  border-radius: rem(70px);
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10px);
  background-color: #338891;

  p {
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;
    color: #fff;
  }
}

.spinner {
  width: rem(50px);
  height: rem(20px);
}

.spinnerDiv {
  width: rem(50px);
}

.dreamRow {
  display: flex;
  align-items: center;
  gap: rem(10px);
  @media (max-width: 835px) {
    gap: rem(2px);
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(10px);
  }
}

.aiDreamErrorContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.errorMessage {
  color: #db4f4fcc;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(24px);
  letter-spacing: -0.32px;
  margin-left: rem(28px);
}

.skeletonShape {
  background: linear-gradient(90deg, #e5e5e5 25%, #d0d0d0 50%, #e5e5e5 75%);
  background-size: 200% 100%;
  animation: flash 2s linear infinite;
  border-radius: 30px;
  margin-bottom: 8px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(10px);
  margin-top: rem(4px);
  width: 100%;
}

.inputWrapper {
  padding-right: rem(22px);
  label {
    max-width: unset;
  }
}

.btnUpdated {
  display: flex;
  width: rem(220px);
  height: rem(42px);
  padding: rem(11px) rem(29px);
  border-radius: 70px;
  border: none;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  color: #fff;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(24px);
  letter-spacing: 1.6px;
  &.disabled,
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  position: relative;
  background-color: #db4f4f;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 200% 200%;
  }

  &:hover {
    animation: bgColorChangeUpdated 0.1s 0.4s forwards;
  }

  &:hover::before {
    background-image: linear-gradient(
      225deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.7) 50%,
      rgba(255, 255, 255, 0.7) 52%,
      rgba(255, 255, 255, 0) 52%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: shineEffect 0.6s ease-in forwards;
  }
}

.btnText {
  color: #5ca0a7;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(24px);
  letter-spacing: 1.6px;
}

.tooltip {
  margin-right: rem(20px);
}

.additionalText {
  height: rem(95px);
  @media (max-width: 600px) {
    height: rem(120px);
  }
}
