@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 12px 24px 4px rgba(0, 0, 0, 0.1);
  border-radius: rem(20px);
}
