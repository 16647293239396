@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.errorContainer {
  color: red;
  font-size: rem(15px);
  min-height: rem(24px);
  letter-spacing: 0.5px;
  transition: all 0.15s ease-in;
  font-weight: 500;
}
