@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  position: relative;
  width: calc(100vw - 333px);
  display: flex;
  justify-content: flex-end;
}

.dropdown {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 80px;
  z-index: 2;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }

  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
}

.dropdownShown {
  visibility: visible;
  position: absolute;
  top: 100%;
  right: 80px;
  z-index: 2;
}

.button {
  background: transparent;
  border: none;
}
