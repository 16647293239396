@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.contentContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 rem(20px) rem(20px) rem(20px);
}

.question {
  font-size: rem(18px);
  font-weight: 600;
  margin-top: rem(20px);
}

.answer {
  font-size: rem(16px);
  font-weight: 400;
  margin-left: rem(16px);
  margin-bottom: rem(5px);
}
