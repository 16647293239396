@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.experienceLocation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(15px);
}

.radioButtons {
  display: flex;
  gap: rem(30px);
}

.text {
  font-size: rem(16px);
  font-weight: 500;
  margin-left: -8px;
  // margin-bottom: rem(10px);
  // margin-left: rem(10px);
  max-width: 360px;
}

.greenText {
  font-size: rem(14px);
  font-weight: 500;
  color: #338891;
}

.tableRow {
  display: flex;
}

.tableBodyRow {
  display: flex;
  padding-top: rem(12px);
  margin-bottom: rem(20px);
}

.tableHeaderText {
  font-size: rem(16px);
  font-weight: 500;
  color: #338891;
  text-decoration: underline;
}

.text1 {
  min-width: rem(50px);
  text-align: center;
  margin-right: rem(20px);
}

.text2 {
  min-width: rem(90px);
}

.text3 {
  min-width: rem(90px);
}

.contentContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: rem(950px);
  flex: 1;
  margin-right: rem(20px);
}

.pbutton {
  color: white;
  align-self: center;
  font-size: rem(16px);
  font-weight: 500;

  @media (max-width: 950px) {
    display: none;
  }
}

.regenerateButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  gap: rem(4px);
  // max-height: 20px;
  margin-left: auto;
  padding: rem(2px) 0;
  padding-left: rem(5px);
  padding-right: rem(15px);
  user-select: none;
  @media (max-width: 950px) {
    padding-right: rem(5px);
  }
}

.regenerateContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  gap: rem(4px);
  align-self: flex-start;
  // max-height: 20px;
  margin-left: 10px;
  padding: rem(2px) 0;
  padding-left: rem(5px);
  padding-right: rem(15px);
  user-select: none;
  @media (max-width: 950px) {
    padding-right: rem(5px);
  }
}

.surveyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: rem(20px);
  // padding-right: rem(150px);
  @media (max-width: 630px) {
    // flex-direction: column;
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
}

.surveyText {
  min-width: 120px;
  margin-right: 30px;
  align-self: flex-start;
  @media (max-width: 630px) {
    display: none;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  // gap: rem(8px);
  margin-left: rem(10px);
  height: fit-content;
  width: rem(50px);

  @media (max-width: 630px) {
    width: rem(40px);
  }
}

.tasksDescriptionContainer {
  display: flex;
  flex-direction: row;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: rem(10px);
}

.selectContainer {
  display: flex;
  gap: rem(10px);
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 380px) {
    justify-content: flex-start;
  }
}

.selectA {
  max-width: rem(120px);
}

.saveButton {
  width: rem(30px);
}

.footer {
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-top: rem(20px);

  @media (max-width: 500px) {
    p {
      font-size: rem(12px);
    }
  }
}

.changeableText {
  min-width: 150px;
  align-self: center;
  // margin-bottom: rem(10px);
  // font-size: rem(14px);
  // color: #1e1e1e99;
  // line-height: rem(24px);
  // font-weight: 400;
  @media (max-width: 630px) {
    display: none;
  }
}

.selectionText {
  min-width: 150px;
  // margin-top: rem(20px);
  // margin-bottom: rem(20px);

  @media (max-width: 630px) {
    display: none;
  }
}

.textareasRow {
  display: flex;
  // justify-content: space-between;
  // gap: rem(10px);
  // flex: 1;

  @media (max-width: 630px) {
    flex-direction: column;
  }
}

.inputs {
  display: flex;
  flex: 1;
  margin-right: rem(100px);
  gap: rem(10px);
  flex-direction: column;
  @media (max-width: 630px) {
    margin-left: 0;
  }
}

.addOption {
  cursor: pointer;
  text-decoration: underline;
  font-size: rem(15px);
}

.addOther {
  cursor: pointer;
  text-decoration: underline;
  font-size: rem(15px);
}

.addOptionDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.addOptions {
  display: flex;
  gap: rem(10px);
  margin-bottom: rem(10px);
}

.inputContainer {
  display: flex;
  flex: 1;
  gap: rem(10px);
  // align-items: center;

  svg {
    cursor: pointer;
  }
}
.objectiveContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 630px) {
    flex-direction: column;
    align-items: flex-start;
  }
  // justify-content: space-between;
}

.objectiveHeadline {
  font-family: 'Avenir';
  color: rgba(51, 136, 145, 0.8);
  font-size: rem(20px);
  font-weight: 900;
  line-height: rem(25px);
  letter-spacing: rem(1px);
  color: rgba(51, 136, 145, 0.8);
  @media (max-width: 630px) {
    display: none;
  }
}
.objectiveHeadline2 {
  font-family: 'Avenir';
  color: rgba(51, 136, 145, 0.8);
  font-size: rem(20px);
  font-weight: 900;
  line-height: rem(25px);
  letter-spacing: rem(1px);
  margin-left: rem(20px);
  color: rgba(51, 136, 145, 0.8);
  @media (max-width: 630px) {
    font-size: rem(16px);
    font-weight: 500;
    line-height: rem(24px);
    align-self: flex-start;
    margin-left: 0;
  }
}

.tooltip {
  margin-top: 5px;
  // @media (max-width: 630px) {
  //   display: none;
  // }
}
.userActionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: rem(20px);
  padding-right: rem(100px);
  @media (max-width: 950px) {
    padding-right: rem(50px);
  }
  @media (max-width: 900px) {
    padding-right: rem(30px);
  }
  @media (max-width: 830px) {
    padding-right: rem(20px);
  }
  @media (max-width: 630px) {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
}

.timeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 380px) {
    flex-direction: column;
    align-items: flex-start;
    gap: rem(16px);
  }
}

// .time {
//   @media (max-width: 630px) {
//     display: none;
//   }
// }

.timeText {
  margin-left: rem(110px);
}

.tasksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 630px) {
    // flex-direction: row;
  }
}

.taskText {
  font-family: 'Avenir';
  font-size: rem(16px);
  font-weight: 500;
  line-height: rem(25px);
  letter-spacing: rem(1px);
  text-align: left;
  min-width: 120px;
  @media (max-width: 630px) {
    display: none;
  }
}

.urlInputContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 630px) {
    flex-direction: column;
  }
}

.inputLabel {
  min-width: 150px;
  align-self: flex-start;
  @media (max-width: 630px) {
    display: none;
  }
}

.companyLabel {
  min-width: 150px;
  align-self: flex-start;
  color: rgba(0, 0, 0, 1);
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;

  @media (max-width: 630px) {
    display: none;
  }
}

.hashtagContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 630px) {
    flex-direction: column;
  }
}

.hashtagLabel {
  text-align: left;
  align-self: flex-start;
  min-width: 150px;
  @media (max-width: 630px) {
    display: none;
  }
}

.spinner {
  width: rem(50px);
  height: rem(20px);
}

.taskSpinner {
  width: rem(235px);
  height: rem(20px);
  align-self: flex-start;
}

.spinnerDiv {
  width: rem(50px);
}

.duplicateButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: rem(5px) 0;
}

.deleteButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: rem(5px) 0;
  margin-left: auto;
  @media (max-width: 630px) {
  }
}

.duplicateText {
  align-self: center;
  font-size: rem(16px);
  font-weight: 500;

  @media (max-width: 850px) {
    display: none;
  }
}

.manageContainer {
  display: flex;
  align-items: center;
  padding-right: rem(35px);
  padding-top: rem(10px);

  @media (max-width: 630px) {
    flex-direction: row;
    padding-right: 0;
    justify-content: space-between;
  }
}

.manageButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 850px) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.manageText {
  min-width: 150px;

  // @media (max-width: 630px) {
  //   display: none;
  // }
}

.selectionTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(10px);
}

.duplicateInputContainer {
  width: 100%;
  margin-left: rem(12px);
  margin-right: rem(8px);
  @media (max-width: 950px) {
    margin-right: rem(50px);
  }
  @media (max-width: 900px) {
    margin-right: rem(30px);
  }
  @media (max-width: 830px) {
    margin-right: rem(20px);
  }
  @media (max-width: 790px) {
    margin-right: rem(25px);
  }
}

.sortContainer {
  border: 1px solid #00000033;
  user-select: none;
  border-radius: 4px;
  width: 26px;
  // max-height: 256px;
  margin-right: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  div {
    cursor: pointer;
  }
}

.visitContainer {
  position: absolute;
  right: -100px;
  background-color: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  min-width: 70px;
  padding: rem(1px) 0;
  padding-left: rem(5px);
  padding-right: rem(45px);
  align-items: center;
  max-height: 26.4px;
  margin-bottom: rem(24px);
  cursor: pointer;
  user-select: none;

  p {
    margin-left: 25px;
    font-family: 'Avenir';
    align-self: center;
    font-size: rem(16px);
    font-weight: 500;
    // line-height: 25px;
    // letter-spacing: 1px;
    color: white;
    @media (max-width: 950px) {
      display: none;
    }
  }
  @media (max-width: 950px) {
    padding-right: rem(5px);
    padding-left: rem(5px);
    min-width: 0px;
    right: -50px;
  }
  @media (max-width: 900px) {
    right: -30px;
  }
  @media (max-width: 830px) {
    right: -20px;
  }
  @media (max-width: 630px) {
    display: none;
  }
}

.hashtagsContainer {
  position: absolute;
  right: -100px;
  background: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  min-width: 70px;
  padding: rem(1px) 0;
  padding-left: rem(5px);
  padding-right: rem(30px);
  display: flex;
  align-items: center;
  max-height: 26.4px;
  cursor: pointer;
  user-select: none;

  p {
    margin-left: 25px;
    font-family: 'Avenir';
    align-self: center;
    font-size: rem(16px);
    font-weight: 500;
    // line-height: 25px;
    // letter-spacing: 1px;
    color: white;
    @media (max-width: 950px) {
      display: none;
    }
  }
  @media (max-width: 950px) {
    padding-right: rem(5px);
    padding-left: rem(5px);
    min-width: 0px;
    right: -50px;
  }
  @media (max-width: 900px) {
    right: -30px;
  }
  @media (max-width: 830px) {
    right: -20px;
  }
  @media (max-width: 630px) {
    display: none;
  }
}

.assistContainer {
  position: absolute;
  right: -100px;
  background: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  min-width: 70px;
  padding: rem(1px) 0;
  padding-left: rem(5px);
  padding-right: rem(35px);
  display: flex;
  align-items: center;
  max-height: 26.4px;
  cursor: pointer;
  user-select: none;

  p {
    margin-left: 25px;
    font-family: 'Avenir';
    align-self: center;
    font-size: rem(16px);
    font-weight: 500;
    // line-height: 25px;
    // letter-spacing: 1px;
    color: white;
    @media (max-width: 950px) {
      display: none;
    }
  }
  @media (max-width: 950px) {
    padding-right: rem(5px);
    min-width: 0px;
    right: -50px;
  }
  @media (max-width: 900px) {
    right: -30px;
  }
  @media (max-width: 830px) {
    right: -20px;
  }
  @media (max-width: 630px) {
    display: none;
  }
}

.companyInput {
  background: linear-gradient(
    0deg,
    rgba(51, 136, 145, 0.2),
    rgba(51, 136, 145, 0.2)
  );

  color: rgba(0, 0, 0, 1);
  box-shadow: 4px 4px 10px 0px #5ca0a740;
  width: 100%;
}

.companyInput::placeholder,
.textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Firefox */
.companyInput::-moz-placeholder,
.textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Internet Explorer and Edge */
.companyInput:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Opera */
.companyInput::-ms-input-placeholder,
.textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.getSocialAccountSuggestionsMessageContainer {
  display: flex;
  align-items: center;
  padding-left: 150px;
  padding-bottom: 10px;
  @media (max-width: 630px) {
    padding-left: 0;
  }
}

.relativeInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.relativeDescriptionInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: rem(12px);
  margin-right: rem(8px);
  @media (max-width: 950px) {
    margin-right: rem(50px);
  }
  @media (max-width: 900px) {
    margin-right: rem(30px);
  }
  @media (max-width: 830px) {
    margin-right: rem(20px);
  }
  @media (max-width: 790px) {
    margin-right: rem(25px);
  }
}

.duplicatedInput {
  height: rem(90px) !important;
}

.surveyInput {
  height: rem(66px) !important;
}

.objectiveDuplicate {
  cursor: pointer;
  background-color: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  width: rem(30px);
  color: white;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  margin-left: rem(10px);
}

.objectiveRemove {
  cursor: pointer;
  background-color: #ff000080;
  border-radius: 30px;
  width: rem(30px);
  color: white;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.emptyDiv {
  width: rem(30px);
  margin-left: auto;
}
