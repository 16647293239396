@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.chipContainer {
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  min-width: rem(72px);
  // height: rem(25px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #d8ae00;
  padding: 0 rem(10px);

  p {
    font-family: $eycrowd-font-stack;
    font-weight: 700;
    font-size: rem(12px);
    color: #d8ae00;
  }
}
