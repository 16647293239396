@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  display: flex;
  flex-direction: column;
}

.tooltipOverride {
  background-color: $eycrowd-color-white !important;
  border: 1px solid #338891 !important;
  border-radius: $eycrowd-radius-sm !important;
  width: rem(290px);
  opacity: 1 !important;
  padding: 10px !important;
  overflow-wrap: break-word !important;
}

.label {
  color: $eycrowd-color-gray;
  font-size: $eycrowd-font-size-xs;
  font-weight: 500;
}

.iconContainer {
  display: flex;
  flex-direction: row;
}

.iconContainerText {
  font-family: 'Avenir';
  font-size: rem(24px);
  font-weight: 400;
  line-height: rem(32px);
  letter-spacing: 0px;
  text-align: left;
}
