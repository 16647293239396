@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.label {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(24px);
  color: black;
}

.disabledLabel {
  opacity: 0.8;
}

.styledInput {
  width: 100%;
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: #00000010;
  color: #121212;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(16px);
  height: rem(32px);
  padding: 0 rem(22px);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e90;
  }
}

.textarea {
  height: rem(80px);
  width: 100%;
  background-color: #00000010;
  padding: 0 rem(22px);
  color: #121212;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(24px);
  outline: none;
  border-radius: 2px;
  border: none;
  padding-top: rem(10px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: #1e1e1e90;
  }

  &:focus {
    box-shadow: none;
  }
}

.requiredValue {
  position: absolute;
  right: rem(5px);
}

.rightIcon {
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
}

.errorBorder {
  border: 1px solid red;
}
