@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.rowContainer {
  display: flex;
  margin-right: rem(20px);

  @media (max-width: 630px) {
    flex-direction: column;
    margin-right: rem(20px);
  }

  @media (max-width: 500px) {
    margin-right: rem(0px);
  }
}

.rowContainerWithoutError {
  margin-bottom: rem(20px);
}

.rowActions {
  width: rem(50px);

  display: flex;
  flex-direction: row;
  gap: rem(10px);
  height: fit-content;

  @media (max-width: 630px) {
    display: none;
  }
}

.rowContent {
  width: 100%;
}

.rowLabel {
  min-width: rem(216px);
  max-width: rem(216px);

  margin-right: rem(20px);
  font-weight: 400;

  @media (max-width: 630px) {
    margin-bottom: rem(8px);
  }
}

.rowDescription {
  color: #338891;
  font-size: rem(13px);
  margin-bottom: rem(5px);
  margin-top: rem(5px);
}

.rowActionsContainer {
  display: flex;
  gap: rem(16px);
  height: rem(32px);
  align-items: flex-end;

  p {
    color: #db4f4f;
    cursor: pointer;
  }
}

.separator {
  height: rem(40px);
}

.datePicker {
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: #00000010;
  color: #121212;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(24px);
  height: rem(32px);
  padding: 0 rem(22px);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e90;
  }
}

.timePicker {
  width: 100%;
}

.searchContainer {
  height: rem(120px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: rem(20px);
  margin-bottom: rem(10px);
  align-content: flex-start;

  .searchableCheckboxWrapper:first-child {
    width: 100%;
  }

  @media (max-width: 630px) {
    padding-left: unset;
  }

  &::-webkit-scrollbar {
    width: rem(12px);
  }

  &::-webkit-scrollbar-thumb {
    background: #338891;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #33889120;
    border-radius: 2px;
  }

  label {
    width: 50%;
  }
}

.line {
  height: 1px;
  background: #1e1e1e60;
}

.checkboxesContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: rem(5px);
  padding-left: rem(20px);

  @media (max-width: 630px) {
    padding-left: unset;
  }

  .checkboxWrapper:first-child {
    width: 100%;
  }

  .checkboxWrapperAgeRanges:first-child {
    width: 100%;
  }
}

.checkboxWrapper {
  margin-bottom: rem(20px);
  width: 50%;
  height: fit-content;

  label {
    width: fit-content;
  }
}

.checkboxWrapperAgeRanges {
  margin-bottom: rem(20px);
  width: 50%;
  height: fit-content;

  label {
    width: fit-content;
  }
}

.searchableCheckboxWrapper {
  margin-bottom: rem(20px);
  width: 50%;

  label {
    width: fit-content;
  }
}

.locationsSearch {
  label {
    width: 100%;
  }
}

.title {
  text-decoration: underline;
  margin-bottom: 20px;
}

.windowStartContainer {
  display: flex;
  gap: rem(20px);

  > div {
    flex: 1;
  }
}

.contentContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: rem(950px);
  flex: 1;
}

.poweredByGoogleLogo {
  height: rem(18px);
  width: rem(144px);
}

.poweredByGoogleLogoWrapper {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  padding-bottom: rem(8px);
  margin-left: rem(20px);

  @media (max-width: 630px) {
    margin-left: unset;
  }
}

.footer {
  display: flex;
  flex: 1;
  gap: rem(5px);
  flex-direction: column;
  margin-top: rem(20px);

  @media (max-width: 500px) {
    p {
      font-size: rem(12px);
    }
  }
}

.summaryContainer {
  margin-left: rem(20px);

  @media (max-width: 500px) {
    margin-left: rem(0px);
    margin-right: rem(20px);
  }
}

.required {
  position: absolute;
  right: rem(55px);
  top: rem(0px);
}

.spinnerEmptyDiv {
  width: rem(50px);
}

.windowEnd {
  margin-left: rem(20px);

  @media (max-width: 630px) {
    margin-left: unset;
  }
}

.tooltip {
  height: rem(20px);
  margin-top: rem(5px);
}

.private {
  display: flex;
  flex-direction: column;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
  gap: rem(10px);

  div > p {
    width: rem(60px);
  }
}

.eyvocateMix {
  display: flex;
  flex-direction: column;
  gap: rem(14px);
}

.fileInputCard {
  display: flex;

  align-items: center;
  justify-content: center;
  height: rem(60px);
  background-color: #00000010;

  border-radius: 2px;
  position: relative;

  p {
    font-weight: 400;
    font-size: rem(15px);
    text-align: center;
    width: 80%;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.endDateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateFieldContainer {
  position: relative;
  display: flex;
}

.endTimeText {
  font-weight: 500;
  margin-right: rem(16px);
}

.mobileLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: rem(4px);
}

.copyContainer {
  background: rgba(92, 160, 167, 0.8);
  display: flex;
  gap: rem(10px);
  align-items: center;
  border-radius: rem(30px);
  padding: rem(2px) rem(9px);
  cursor: pointer;

  p {
    color: white;
    font-weight: 600;
    user-select: none;
  }
}
.spinner {
  width: rem(50px);
  height: rem(34px);
}

.spinnerDiv {
  display: flex;
}

.privateExperienceText {
  margin-bottom: rem(10px);
}
