@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: rem(30px);

  @include media-max($eycrowd-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.cursorDisabled {
  cursor: not-allowed;
}
