@import '../../../../styles/abstract/mixins';
@import '../../../../styles/abstract/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(150px) 0;

  @include media-max($eycrowd-screen-mobile-portrait-lg) {
    padding: rem(20px);
  }
}
