@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.inputField {
  padding: rem(13px) rem(44px);
}

.smallInput {
  padding: rem(13px) rem(14px);
}

.inputWrapper {
  border-radius: rem(10px);
  border: 1px solid #1e1e1e;
  height: rem(46px);
  position: relative;
  width: rem(240px);
}

.smallInputWrapper {
  width: rem(82px);
}

.cardIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: rem(17px);
  width: rem(20px);
}

.inputTitle {
  position: absolute;
  font-size: rem(10px);
  font-weight: 400;
  color: #1e1e1e;
  top: -8px;
  left: 10px;
  background-color: #f5f5f5;
  padding: 0 3px;
}

.paymentMethodContainer {
  display: flex;
  gap: rem(24px);

  @media (max-width: 630px) {
    flex-direction: column;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: rem(22px);
  margin-bottom: rem(30px);

  @media (max-width: 630px) {
    width: 100%;
    padding-right: rem(20px);
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(10px);
}

.amountWrapper {
  display: flex;
  width: rem(82px);
  height: rem(42px);
  align-items: center;
  justify-content: center;
  background: #33889126;
  border-radius: 10px;

  p {
    font-weight: 500;
    font-size: rem(14px);
  }
}

.title {
  font-size: rem(20px);
  font-weight: 600;
  line-height: rem(32px);
  letter-spacing: -0.02em;
}

.brandIconWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: rem(17px);
  max-height: rem(28px);
  width: rem(30px);
}
