@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.modalWrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  max-width: rem(400px);
  background-color: $eycrowd-color-white;
  padding: rem(25px) rem(28px);
}

.modalTitle {
  color: $eycrowd-color-red;
  font-size: $eycrowd-font-size-sm;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: rem(26px);
}

.cardInfoFieldGroup {
  padding: rem(8px) 0;
}

.label {
  font-size: $eycrowd-font-size-sm;
  line-height: 1.1;
  color: $eycrowd-color-light-gray-01;
  margin-bottom: rem(4px);
  font-weight: 500;
  display: flex;
}

.inputField {
  height: rem(44px);
  width: 100%;
  padding: rem(10px) 0 rem(10px) rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: $eycrowd-radius-sm;
  outline: none;
  font-family: $eycrowd-font-stack;
  color: $eycrowd-color-black-02;
  margin-bottom: rem(16px);
}

.modalSubmitButton {
  width: rem(205px);
  font-size: $eycrowd-font-size-sm;
  font-weight: 600;
  border-radius: $eycrowd-radius-sm;
  padding: rem(12px) rem(20px);
  margin: rem(24px) auto rem(12px);
}

.additionalInfoText {
  color: $eycrowd-color-light-gray-01;
  font-size: rem(14px);
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}
