@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

@keyframes ellipsis {
  0%,
  100% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.container {
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
}

.contentContainer {
  position: absolute;
  left: rem(232px);
  // top: rem(160px);
  // bottom: rem(90px);
  width: rem(730px);
  overflow: auto;
  padding-bottom: rem(30px);

  &::-webkit-scrollbar {
    width: rem(12px);
  }

  &::-webkit-scrollbar-thumb {
    background: #338891;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #33889120;
    border-radius: 2px;
  }

  @media (max-width: 1260px) {
    width: unset;
    right: rem(290px);
  }

  @media (max-width: 1190px) {
    left: rem(20px);
  }

  @media (max-width: 790px) {
    right: rem(20px);
  }
}

.helpContent {
  width: rem(998px);

  @media (max-width: 1260px) {
    width: unset;
    right: rem(20px);
  }
}

.contentBottomPadding {
  bottom: rem(133px);
}

.contentBottomPadding1 {
  bottom: rem(160px);
}

.projectedValuesContainer {
  position: fixed;
  left: rem(982px);
  bottom: rem(90px);

  @media (max-width: 1260px) {
    left: unset;
    right: rem(20px);
  }

  @media (max-width: 790px) {
    position: relative;
    display: flex;
    justify-content: center;
    inset: unset !important;
    margin-top: rem(20px);
  }
}

.footer {
  display: flex;
  flex: 1;
  gap: rem(5px);
  flex-direction: column;
  margin-top: rem(20px);

  @media (max-width: 500px) {
    p {
      font-size: rem(12px);
    }
  }
}

.welcomeToEycContainer {
  width: rem(250px);
  border-radius: 10px;
  padding: rem(10px) 0 rem(18px) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
  background-color: white;
  height: 100%;
  overflow-x: auto;

  @media (max-width: 790px) {
    display: none;
  }

  h1 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 2px;
    text-align: center;
  }
}

.textContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 2px;
    text-align: center;
  }
}

.helpCenter {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: rem(10px);
}

.buttonsContainer {
  position: fixed;
  left: rem(232px);
  bottom: rem(20px);
  display: flex;
  flex-direction: column;
  border-top: 1px solid #33889150;
  width: rem(998px);

  @media (max-width: 1260px) {
    width: unset;
    right: rem(20px);
  }
  @media (max-width: 990px) {
    left: rem(20px);
  }
}

.buttonsWrapper {
  display: flex;
  gap: rem(30px);
  margin-top: rem(27px);
}

.ivitationContainer {
  width: rem(1250px);
  display: flex;
  flex-direction: column;
}

.header {
  height: rem(80px);
  width: 100%;
  display: flex;
  align-items: center;
  padding-inline: rem(20px);
  justify-content: space-between;
}

.ivitationContent {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  flex: 1;
  padding: rem(120px) rem(150px);
  gap: rem(50px);
}

.logo {
  height: rem(30px);
}

.headerButtons {
  display: flex;
  gap: rem(22px);
}

.btn {
  border: none;
  background-color: #e0685e;
  border: 2px solid #e0685e;
  border-radius: 50px;
  height: rem(50px);
  width: rem(190px);
  font-size: rem(18px);
  font-weight: 600;
  line-height: rem(25px);
  color: #fff;
}

.firstSection {
  display: flex;
}

.firstSectionContent {
  flex: 1;
}

.logoY {
  width: rem(250px);
}
.eycLogo {
  margin-top: rem(10px);
  width: 180px;
  height: 35px;
  align-self: center;
}

.title {
  color: #fff;
  font-size: rem(46px);
  font-weight: 600;
}

.subtitle {
  color: #fff;
  font-size: rem(42px);
  font-weight: 400;
}

.firstSectionText {
  color: #fff;
  font-size: rem(25px);
  font-weight: 400;
}

.firstSectionInputs {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  margin-top: rem(40px);

  div {
    display: flex;
    gap: rem(34px);
  }
}

.thirdSection {
  display: flex;
  gap: rem(32px);
}

.welcomeToEycContainerText {
  font-family: 'Avenir';
  margin-top: rem(8px);
  font-size: rem(24px);
  font-weight: 800;
  line-height: rem(25px);
  letter-spacing: rem(1px);
  text-align: center;
}
.welcomeToEycLogoContainer {
  display: flex;
  flex-direction: row;
}
.welcomeToEycContainerTrademark {
  margin-top: -6px;
  font-size: rem(14px);
  font-weight: 800;
  line-height: rem(28px);
  letter-spacing: rem(1px);
  text-align: left;
}

.infoContainer {
  position: absolute;
  left: rem(1020px);
  top: rem(43px);
  display: flex;

  @media (max-width: 1245px) {
    left: unset;
    right: rem(200px);
  }
  @media (max-width: 860px) {
    top: rem(45px);
  }

  @media (max-width: 790px) {
    top: rem(65px);
    right: rem(66px);
  }

  @media (max-width: 700px) {
    right: rem(22px);
  }
}

.tooltipContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltipIcon {
  width: rem(27px) !important;
  height: rem(27px) !important;
  margin: 0 !important;
}

.infoText {
  margin-left: rem(8px);
  font-family: 'Avenir';
  font-size: rem(24px);
  font-weight: 400;
  line-height: rem(32px);
  letter-spacing: 1.5px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  @media (max-width: 790px) {
    display: none;
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: rem(8px);
  padding-right: rem(8px);
  margin-left: auto;
  cursor: pointer;
  @media (max-width: 790px) {
    align-self: flex-start;
  }
}

.ellipsis:after {
  content: '';
  animation: ellipsis 1.5s infinite;
}

.lineThrough {
  text-decoration: line-through;
}

.textdiv {
  display: flex;
  align-items: center;
  gap: rem(10px);
}
