@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.separator {
  height: rem(40px);
}

.campaignTypeContainer {
  margin-bottom: rem(30px);
}

.contentContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: rem(950px);
  flex: 1;
}

.checkboxesContainer {
  display: flex;
  flex-direction: row;
  gap: rem(60px);

  @media (max-width: 630px) {
    gap: rem(5px);

    label {
      flex: 1;
    }
  }
}

.authorizeActions {
  height: rem(35px);
  width: rem(300px);
  border-radius: rem(100px);
  background-color: #e6e6e6;
  display: flex;
  padding: rem(2px);
  cursor: pointer;
}

.btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(100px);

  p {
    font-size: rem(14px);
    font-weight: 500;
  }
}

.selectedBtn {
  background-color: #e89392;

  p {
    color: white;
  }
}

.inputContainer {
  display: flex;
}

.tooltip {
  padding-top: rem(9px);
}

.summaryContainer {
  margin-left: rem(20px);
}

.footer {
  display: flex;
  flex: 1;
  gap: rem(5px);
  flex-direction: column;
  margin-top: rem(20px);

  @media (max-width: 500px) {
    p {
      font-size: rem(12px);
    }
  }
}

.spinner {
  width: rem(50px);
  height: rem(34px);
}

.spinnerDiv {
  display: flex;
}
.windowStartContainer {
  display: flex;
  gap: rem(20px);

  > div {
    flex: 1;
  }
}

.endDateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateFieldContainer {
  position: relative;
}

.endTimeText {
  font-weight: 500;
  margin-right: rem(16px);
}

.required {
  position: absolute;
  right: rem(55px);
  top: rem(0px);
}

.spinnerEmptyDiv {
  width: rem(50px);
}

.datePicker {
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: #00000010;
  color: #121212;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(24px);
  height: rem(32px);
  padding: 0 rem(22px);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e90;
  }
}

.windowEnd {
  margin-left: rem(20px);

  @media (max-width: 630px) {
    margin-left: unset;
  }
}

.fileInputCard {
  display: flex;

  align-items: center;
  justify-content: center;
  height: rem(60px);
  background-color: #00000010;

  border-radius: 2px;
  position: relative;

  p {
    font-weight: 400;
    font-size: rem(15px);
    text-align: center;
    width: 80%;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.datePicker {
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: #00000010;
  color: #121212;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(24px);
  height: rem(32px);
  padding: 0 rem(22px);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e90;
  }
}

.urlInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-right: rem(20px);
  :last-child {
    // max-width: 450px;
    margin-left: auto;
  }
  @media (max-width: 630px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    margin-right: rem(0px);
  }
}

.inputLabel {
  min-width: 150px;
  align-self: flex-start;
  @media (max-width: 630px) {
    display: none;
  }
}

.companyLabel {
  min-width: 265px;
  align-self: flex-start;
  color: rgba(0, 0, 0, 1);
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;

  @media (max-width: 630px) {
    margin-bottom: 10px;
  }
}

.companyInput {
  background: linear-gradient(
    0deg,
    rgba(51, 136, 145, 0.2),
    rgba(51, 136, 145, 0.2)
  );

  color: rgba(0, 0, 0, 1);
  box-shadow: 4px 4px 10px 0px #5ca0a740;
}

.companyInput::placeholder,
.textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Firefox */
.companyInput::-moz-placeholder,
.textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Internet Explorer and Edge */
.companyInput:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Opera */
.companyInput::-ms-input-placeholder,
.textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.spinner {
  width: rem(50px);
  height: rem(34px);
}

.spinnerDiv {
  display: flex;
}

.endDateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateFieldContainer {
  position: relative;
  display: flex;
}

.guessContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  margin-left: 15px;
  cursor: pointer;
  padding: rem(1px) 0;
  padding-left: rem(20px);
  padding-right: rem(40px);
  @media (max-width: 950px) {
    padding: rem(1px) rem(2px);
  }
  @media (max-width: 630px) {
    display: none;
  }
}

.pbutton {
  color: white;
  font-size: rem(16px);
  font-weight: 500;

  @media (max-width: 630px) {
    display: none;
  }
}

.assistButton {
  margin-left: -15px;
  margin-right: 20px;
  @media (max-width: 950px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.visitUrlContainer {
  background: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: rem(24.4px);
  padding-left: rem(5px);
  padding-right: rem(45px);
  cursor: pointer;
  user-select: none;
  margin-left: 15px;

  p {
    margin-left: 25px;
    font-family: 'Avenir';
    align-self: center;
    font-size: rem(16px);
    font-weight: 500;
    // line-height: 25px;
    // letter-spacing: 1px;
    color: white;
    @media (max-width: 950px) {
      display: none;
    }
  }
}

.relativeInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.regenerateContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(92, 160, 167, 0.8);
  border-radius: 30px;
  gap: rem(4px);
  // max-height: 20px;
  margin: 0 rem(22px);
  padding: rem(2px) 0;
  padding-left: rem(5px);
  padding-right: rem(15px);
  user-select: none;
  margin-right: rem(15px);
  @media (max-width: 630px) {
    padding-right: rem(5px);
  }
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
