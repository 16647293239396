@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBox {
  padding: rem(30px);
}

.button {
  margin-top: rem(10px);
}
