@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  width: 100%;
  display: flex;
  gap: rem(20px);
  align-items: center;
}

.valueContainer {
  height: rem(32px);
  width: rem(90px);
  background-color: #00000010;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #1e1e1e99;
  }
}

// INPUT RANGE
input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  height: rem(8px);
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// INPUT RANGE THUMB
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: rem(26px);
  width: rem(26px);
  border-radius: 50%;
  background: #338891;
  cursor: pointer;
  margin-top: rem(-9px);
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  height: rem(26px);
  width: rem(26px);
  border-radius: 50%;
  background: #338891;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  height: rem(26px);
  width: rem(26px);
  border-radius: 50%;
  background: #338891;
  cursor: pointer;
}

//  INPUT RANGE TRACK
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: rem(8px);
  cursor: pointer;
  background: #33889120;
  border-radius: 2px;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: rem(8px);
  cursor: pointer;
  background: #33889120;
  border-radius: 2px;
}

input[type='range']::-ms-track {
  width: 100%;
  height: rem(8px);
  cursor: pointer;
  background: #33889120;
  border-color: transparent;
  color: transparent;
}

.sufix {
  margin: 0 rem(5px);
}

.input {
  min-width: rem(40px);
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: rem(10px);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
