@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.label {
  display: flex;
  flex-direction: column;

  font-size: rem(16px);
  line-height: rem(24px);
  color: black;
  width: fit-content;
}

.checkBoxWrapper {
  display: flex;
  cursor: pointer;
}

.hiddenInput {
  visibility: hidden;
  width: 0;
  height: 0;
}

.content {
  font-size: rem(16px);
  line-height: rem(16px);
  color: black;
  align-self: center;
  margin-left: rem(5px);
}

.checked {
  min-width: 16px;
  height: 16px;
  background: #338891;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}

.unchecked {
  min-width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}

.disabledLabel {
  opacity: 0.5;
}

.checkedCircle {
  min-width: 20px;
  height: 20px;
  background: #338891;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 3px;
  background-clip: content-box;
}

.uncheckedCircle {
  min-width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
