@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
}

.cardsContainer {
  display: flex;
  padding-inline: rem(10px);
  padding-top: rem(16px);
  gap: rem(27px);
}

.card {
  display: flex;
  flex: 1;
  box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
  height: rem(420px);
  border-radius: 30px;
  background: #fff;
  padding: rem(14px);
  gap: rem(12px);
  flex-direction: column;
}

.titleContainer {
  display: flex;
  gap: rem(10px);
  padding-inline: rem(12px);
  align-items: center;
  height: fit-content;
}

.title {
  color: #338891;
  font-size: 16.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}

.content {
  border-radius: 10px;
  background: rgba(51, 136, 145, 0.05);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.6rem 0.9rem 0 0.9rem;
}

.button {
  border-radius: 70px;
  background: rgba(51, 136, 145, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  height: rem(32px);
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  cursor: pointer;
}

.contentTitle {
  color: #338891;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.07px;
  margin-bottom: rem(10px);

  span {
    text-decoration-line: underline;
  }
}

.contentText {
  color: #338891;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
}

.contentTextContainer {
  display: flex;
}

.dotContainer {
  height: rem(20px);
  min-width: rem(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #338891;
}

.customContainer {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
  border-radius: 30px;
  background: #fff;
  box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
  align-items: center;
  justify-content: center;
  padding-top: rem(16px);
  padding-bottom: rem(13px);
  margin-inline: rem(10px);

  div {
    width: rem(158px);
  }
}

.disabledCustom {
  opacity: 0.5;
  cursor: not-allowed;
}
