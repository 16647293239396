@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.spinnerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  img {
    width: 3rem;
    height: 3rem;
  }
}
