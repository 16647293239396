@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.buttonsWrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.buttonReset {
  padding: rem(9px) rem(43px);
  margin: rem(15px) rem(5px);
  pointer-events: auto;
  color: #fe5f55;
}

.spinner {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  height: rem(36px) !important;
}

.buttonDraw {
  padding: rem(9px) rem(43px);
  margin: rem(15px) rem(5px);
  pointer-events: auto;
  background: #fe5f55;
}

.positonButton {
  position: absolute;
  pointer-events: auto;
  bottom: rem(110px);
  right: rem(10px);
  width: rem(40px);
  border-radius: rem(2px);
  background: $eycrowd-color-white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.myLocationIcon {
  height: rem(36px);
  color: #666666;
  &:hover {
    color: $eycrowd-color-black;
  }
}

.icon {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: rem(18px);
  z-index: 1;
}
.label {
  display: flex;
  align-items: flex-start;
}

.labelText {
  font-size: $eycrowd-font-size-sm;
  font-weight: 500;
  color: $eycrowd-color-light-gray-01;
}
.input {
  height: rem(44px);
  width: 100%;
  padding: rem(10px);
  border: 1px solid $eycrowd-color-light-gray-01;
  border-radius: rem(10px);
  outline: none;
  font-family: $eycrowd-font-stack;
  margin-bottom: 1rem;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: $eycrowd-color-light-gray-01;
  }

  &:focus {
    border: 2px solid #2c994d;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.cursorDisabled {
  cursor: not-allowed;
}
