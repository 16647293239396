.u-mt-5 {
  margin-top: rem(50px);
}

.u-mt-4 {
  margin-top: rem(44px);
}

.u-mr-md {
  margin-right: rem(20px);
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-pl-md {
  padding-left: rem(21px);
}

.u-align-self-center {
  align-self: center;
}

.u-mb-sm {
  margin-bottom: rem(10px);
}

.u-mb-md {
  margin-bottom: rem(20px);
}

.u-mb-lg {
  margin-bottom: rem(30px);
}

.edit-icon {
  width: rem(16px);
  cursor: pointer;
}
