@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.error {
  min-height: rem(16px);
  margin-left: rem(5px);
  font-size: $eycrowd-font-size-xs;
  color: $eycrowd-color-red;
  font-weight: 500;
}

.altError {
  position: absolute;
  left: rem(5px);
  top: 100%;
}
