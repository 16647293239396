.c-icon {
  height: rem(24px);
  width: rem(24px);
}

.c-icon--sm14 {
  height: rem(14px);
  width: rem(14px);
}

.c-icon--sm {
  height: rem(17px);
  width: rem(17px);
  margin: 0 rem(5px);
}

.c-icon--md24 {
  height: rem(24px);
  width: rem(24px);
  margin: 0 rem(5px);
}

.c-icon--md30 {
  height: rem(30px);
  width: rem(30px);
}

.c-icon--md36 {
  height: rem(36px);
  width: rem(36px);
}

.c-icon--md {
  height: rem(44px);
  width: rem(44px);
}

.c-icon--lg {
  height: rem(60px);
  width: rem(60px);
}

.c-icon--xl {
  height: rem(80px);
  width: rem(120px);
}
