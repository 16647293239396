@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.sidebar {
  position: fixed;
  background-color: white;
  bottom: rem(90px);
  left: rem(20px);
  max-width: rem(192px);
  width: rem(192px);
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  padding: rem(8px) rem(8px) rem(16px) rem(8px);
  overflow: auto;
  @media (max-width: 1190px) {
    display: none;
  }
}

.icon {
  color: #00000060;
  width: rem(30px);
  height: rem(30px);
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
  padding: rem(20px) 0;
  // gap: rem(10px);
}

.sidebarStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(8px);
  width: rem(96px);
  height: rem(96px);
  cursor: pointer;

  p {
    font-size: rem(12px);
    color: #00000033;
    font-weight: 700;
  }
}

.step {
  border: 2px solid #0000004d;
}

.activeSidebarStep {
  border-radius: 10px;
  // box-shadow: 0px 0px 8px 0px #0000001f;
  width: rem(96px);
  height: rem(96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(8px);
  border: 2px solid #5ca0a7;
  // box-shadow: 0px 0px 2px 0px #0000003d;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24);

  p {
    color: #5ca0a7;
  }
}

.step {
  width: rem(38px);
  height: rem(38px);
  border-radius: rem(19px);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: rem(18px);
    color: #00000060;
    font-weight: 600;
  }
}

.activeStep {
  background-color: rgba(51, 136, 145, 0.2);
  border: 2px solid #5ca0a7;
  // background: linear-gradient(
  //   0deg,
  //   rgba(51, 136, 145, 0.4),
  //   rgba(51, 136, 145, 0.4)
  // );
}

.cursor {
  cursor: pointer;
}

.contentBottomPadding {
  bottom: rem(133px);
}

.contentBottomPadding1 {
  bottom: rem(160px);
}

.objectiveFilter {
  width: 100%;
  display: flex;
  flex: 1;
  top: 150px;
  flex-direction: column;
  overflow: auto;
  padding: rem(15px) 0;
  margin-top: rem(28px);
}

.filterType {
  font-size: rem(13px);
  margin-bottom: rem(17px);
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  letter-spacing: 2.2px;
  line-height: 16px;
}

.filterOptions {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
}

.options {
  display: flex;
  align-items: center;
  gap: rem(10px);
  cursor: pointer;
  padding: rem(3px) rem(10px);
  border-radius: 10px;
  border: 2px solid rgba(92, 160, 167, 0.2);
  background: rgba(255, 255, 255, 0.2);
}

.selectedOptions {
  border: 2px solid rgba(92, 160, 167, 0.4);
  background: rgba(92, 160, 167, 0.15);
}

.text {
  color: #999999;
  font-family: Avenir;
  font-size: rem(13px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: 0.52px;
}

.selectedText {
  color: #5ca0a7;
}

.checkbox {
  min-width: rem(26px);
  height: rem(26px);
  border: 1px solid #5ca0a7;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedOption {
  background: #5ca0a7cc;
  width: rem(20px);
  height: rem(20px);
  border-radius: 10px;
}

.contentCard {
  border-radius: 10px;
  border: 1px solid rgba(51, 136, 145, 0.2);
  background: #fff;
  box-shadow: 0px 2px 4px 1px rgba(92, 160, 167, 0.2);
  padding: rem(8px);
  display: flex;
  flex-direction: column;
  gap: rem(8px);
}

.sidebarBtn {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(92, 160, 167, 0.2);
  gap: rem(10px);
  height: rem(30px);
  padding: 0 rem(4px);
  cursor: pointer;
}

.selectedSidebarBtn {
  background: #5ca0a7;
}

.sidebarBtnTextSelected {
  color: #fff !important;
}

.sidebarBtnText {
  color: #5ca0a7;
  font-family: Avenir;
  font-size: rem(13px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: 2.08px;
}

.aiText {
  color: #5ca0a7;
  font-family: Avenir;
  font-size: rem(12px);
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: -0.26px;
  position: absolute;
  right: -3px;
  bottom: 0;
}

.selectedAiText {
  color: #ffffff;
}

.iconContainer {
  position: relative;
  margin-top: rem(5px);
}

.socialMediaFilter {
  border-radius: 10px;
  border: 1px solid rgba(51, 136, 145, 0.2);
  background: #fff;
  box-shadow: 0px 2px 4px 1px rgba(92, 160, 167, 0.2);
  padding: rem(14px);
  margin-top: rem(20px);
  display: flex;
  gap: rem(10px);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.horizontalLine {
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background: linear-gradient(
    270deg,
    white 0%,
    rgba(51, 136, 145, 0.2) 0%,
    #338891 51%,
    rgba(51, 136, 145, 0.2) 100%
  );
}

.filterButton {
  cursor: pointer;
  width: rem(36px);
  height: rem(36px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  border-radius: 5px;
}

.selectedFilterButton {
  border-color: #5ca0a7;
}
