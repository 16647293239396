@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: rem(950px);
  flex: 1;
}

.categoryButton {
  width: rem(200px);
  border-radius: rem(70px);
  border: 1px solid #b4b4bb;
  background-color: transparent;
  height: rem(42px);
  color: #19191d;
  font-size: rem(14px);
  font-weight: 500;
}

.selectedButton {
  background-color: #33889120;
}

.buttonsContainer {
  display: flex;
  gap: rem(26px);
  margin-bottom: rem(10px);
}

.subcategoriesRow {
  display: flex;
  margin-bottom: rem(10px);
  overflow-x: auto;
  height: rem(50px);
}

.objectivesContainer {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  // gap: rem(34px);
  justify-content: center;

  // padding: rem(40px) 0 rem(20px) 0;

  // transform: rotateX(180deg);
  &::-webkit-scrollbar {
    height: rem(12px);
  }
}

.customBackgorundColor {
  &::-webkit-scrollbar-thumb {
    background: #338891;
  }

  &::-webkit-scrollbar-track {
    background: #33889120;
  }
}

.objectivesRow {
  display: flex;
  gap: rem(30px);
  padding-left: rem(20px);

  &::after {
    content: '';
    padding-left: rem(1px);
  }
}

.subcategoryButton {
  font-size: rem(14px);
  font-weight: 700;
  line-height: rem(24px);
  letter-spacing: rem(0.44px);
  border: none;
  outline: none;
  background: transparent;
  white-space: nowrap;
}

.selectedSubcategoryButton {
  text-decoration: underline;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.containerHeader {
  display: none;

  @media (max-width: 1190px) {
    display: flex;
    height: rem(50px);
    overflow-x: auto;
    width: 98%;
    gap: rem(30px);
    margin-bottom: rem(20px);
  }
}

.options {
  display: flex;
  align-items: center;
  gap: rem(8px);
  cursor: pointer;

  @media (max-width: 1190px) {
    border-radius: 4px;
    box-shadow: 0px 2px 2px 0px #00000040;
    background: #ffffff;
    padding: 5px;
  }
}

.text {
  font-size: rem(12px);
  width: max-content;
}

.checkbox {
  min-width: rem(26px);
  height: rem(26px);
  border: 1px solid #5ca0a7;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedOption {
  background: #5ca0a7cc;
  width: rem(20px);
  height: rem(20px);
  border-radius: 10px;
}

.searchContainer {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 4px 4px 10px 0px #33889133;
  border-radius: 30px;
  border: 1px solid rgba(51, 136, 145, 0.4);
  margin-right: rem(30px);
  margin-bottom: rem(18px);
  z-index: 50;
}

.input {
  background-color: white;
  border-radius: 30px;
  padding-left: rem(45px);
}

.searchResultInfo {
  color: #338891;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: left;

  padding-left: rem(28px);
  margin-bottom: rem(18px);
}

.selectedObjectives {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: rem(34px);
}

.horizontalLine {
  height: 1px;
  width: 100%;
  opacity: 0.5;
  margin: rem(12px) 0;
  background: linear-gradient(
    270deg,
    white 0%,
    rgba(51, 136, 145, 0.2) 0%,
    #338891 51%,
    rgba(51, 136, 145, 0.2) 100%
  );
}

.sectionTitle {
  width: 100%;
  text-align: center;
  color: #5ca0a7;
  font-family: Avenir;
  font-size: rem(18px);
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2.08px;
}

.firstTitle {
  margin-top: rem(34px);
}
