@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.separator {
  height: rem(40px);
}

.title {
  text-decoration: underline;
  margin-bottom: 20px;
}

.contentContainer {
  // display: flex;
  // justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: rem(950px);
  flex: 1;
}

.summaryContainer {
  margin-left: rem(20px);
}

.titleText {
  font-size: rem(20px);
  font-weight: 600;
  line-height: rem(32px);
  letter-spacing: -0.02em;
  margin-bottom: rem(10px);
}

.titleContainer {
  // margin-bottom: rem(250px);
}

.selectContainer {
  display: flex;
  gap: rem(10px);
  align-items: center;

  @media (max-width: 630px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: rem(20px);
  }
}

.select {
  width: rem(250px);
}

.couponAmount {
  color: $eycrowd-color-red;
  font-weight: 600;
}

.finalAmount {
  color: $eycrowd-color-green;
  font-weight: 600;
}

.mobileLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: rem(4px);
}

.copyContainer {
  background: rgba(92, 160, 167, 0.8);
  display: flex;
  gap: rem(10px);
  align-items: center;
  border-radius: rem(30px);
  padding: rem(2px) rem(9px);
  cursor: pointer;

  p {
    color: white;
    font-weight: 600;
    user-select: none;
  }
}

.button {
  height: rem(28px) !important;
  width: rem(100px) !important;
}
