@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.label {
  display: flex;
  background: #ffffff;
  border: 2px solid #e0685e;
  height: rem(50px);
  align-items: center;
  border-radius: 50px;
  padding-inline: rem(30px);

  p {
    font-size: rem(18px);
    font-weight: 700;
    margin-right: rem(8px);
  }
}

.styledInput {
  outline: none;
  border: none;
  font-size: rem(18px);
  font-weight: 500;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e90;
  }
}
