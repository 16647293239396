@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  width: rem(250px);
  border-radius: 10px;
  padding: rem(10px) 0 rem(18px) 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dbdbdb;
  background-color: white;
  height: 100%;
  overflow-x: auto;

  @media (max-width: 830px) {
    width: 100%;
    margin-right: rem(10px);
  }
}

.title {
  font-weight: 500;
  font-size: rem(16px);
}

.text {
  font-weight: 400;
  font-size: rem(14px);
  margin-bottom: rem(14px);
}

.sectionTitle {
  font-weight: 400;
  font-size: rem(14px);
  text-decoration: underline;
  margin-bottom: rem(5px);
  text-align: center;
}

.sectionsContainer {
  display: flex;
  gap: rem(12px);
  flex-direction: column;
  align-items: center;
  padding-bottom: rem(35px);
}

.section {
  display: flex;
  flex-direction: column;
  padding: 0 rem(10px);
  gap: rem(4px);
  width: 100%;
}

.valueContainer {
  display: flex;
  gap: rem(16px);
}

.valueNumber {
  font-weight: 700;
  font-size: rem(12px);
  text-align: right;
  width: rem(60px);
}

.valueText {
  font-size: rem(12px);
  font-weight: 400;
}

.helpCenter {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  gap: rem(10px);
  flex: 1;
  margin-top: rem(30px);
}

.welcomeToEycLogoContainer {
  display: flex;
  flex-direction: row;
}
.welcomeToEycContainerTrademark {
  margin-top: -6px;
  font-size: rem(14px);
  font-weight: 800;
  line-height: rem(28px);
  letter-spacing: rem(1px);
  text-align: left;
}

.moreText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: rem(35px);
  text-align: center;
  font-weight: 600;
}

.gradient {
  position: absolute;
  bottom: 0;
  z-index: 40;
  left: 0;
  width: 100%;
  height: rem(50px);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%
  );
}
