@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.styledButton {
  width: rem(220px);
  border-radius: rem(70px);
  border: none;
  background-color: #db4f4f;
  height: rem(42px);
  color: white;
  font-size: rem(16px);
  font-weight: 600;
}

.alt {
  background-color: #cfcfcf;
  color: #7c7c7c;
}

.disabledBtn {
  opacity: 0.4;
  cursor: not-allowed;
}

.spinner {
  height: rem(42px);
}
