@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.styledInput {
  width: 100%;
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #1e1e1e60;
  color: #1e1e1e99;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(24px);
  height: rem(32px);
  padding: 0 rem(22px);
  margin-bottom: rem(15px);
  border-color: #338891;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: #1e1e1e60;
  }
}

.inputContainer {
  position: relative;
}

.icon {
  position: absolute;
  top: rem(4px);
  right: rem(10px);
  cursor: pointer;
}
