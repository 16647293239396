@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.header {
  background-color: white;
  height: rem(72px);
  display: flex;
  align-items: center;
  width: rem(1210px);
  position: fixed;
  top: rem(20px);
  left: rem(20px);
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  display: flex;
  padding: rem(14px) rem(58px) rem(14px) rem(14px);
  @media (max-width: 1260px) {
    width: unset;
    right: rem(20px);
  }

  @media (max-width: 700px) {
    width: unset;
    padding-right: rem(14px);
  }
  @media (max-width: 790px) {
    align-items: normal;
  }
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: rem(16px);
  margin-right: rem(60px);
  flex: 1;

  @media (max-width: 790px) {
    margin-right: rem(0px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: rem(6px);
}

.title {
  font-family: 'Avenir';
  font-size: rem(22px);
  font-weight: 800;
  line-height: rem(28px);
  letter-spacing: rem(1px);
  text-align: left;

  @media (max-width: 500px) {
    font-size: rem(14px);
  }
}

.logo {
  width: rem(40px);
  height: rem(40px);
}

.logoContainerTrademark {
  margin-top: -15px;
  margin-left: -10px;
  font-size: rem(14px);
  font-weight: 800;
  line-height: rem(28px);
  letter-spacing: rem(1px);
  text-align: left;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: rem(164px);

  @media (max-width: 990px) {
    min-width: unset;
  }

  @media (max-width: 590px) {
    display: none;
  }
}

.inputContainer {
  background-color: #f0f0f1;
  border-radius: 30px;
  padding: rem(7px) rem(10px);
  display: flex;
  align-items: center;
  gap: rem(20px);
}

.input {
  width: 100%;
  border: none;
  outline: none;
  font-family: $eycrowd-font-stack;
  background-color: transparent;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:after {
    content: '*';
  }

  &::placeholder {
    color: $eycrowd-color-light-gray-01;
  }
}

.description {
  font-size: rem(16px);
  font-weight: 400;

  @media (max-width: 500px) {
    font-size: rem(12px);
  }
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: rem(10px);

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;
    gap: unset;
  }
  @media (max-width: 990px) {
    :first-child {
      display: none;
    }
  }
}

.helpContainer {
  display: flex;
  flex-direction: row;
  width: rem(100px);
  height: rem(38px);
  // margin-top: rem(5px);
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 0.4);
  border: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.4);

  border-radius: 30px;
  cursor: pointer;

  @media (max-width: 790px) {
    border: none;
    background-color: transparent;
    height: rem(24px);
    width: rem(34px);
    justify-content: flex-end;
  }
}

.helpText {
  margin-left: rem(8px);
  font-family: 'Avenir';
  font-size: rem(16px);
  font-weight: 400;
  line-height: rem(32px);
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 790px) {
    display: none;
  }
}

.fileInputLabel {
  border: 1px solid #ccc;
  background-color: #ccc;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 500;
}

.fileInput {
  display: none;
}
