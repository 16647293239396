@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.textLeft {
  // min-width: rem(300px);
  // max-width: rem(300px);

  // margin-right: rem(20px);
  font-weight: 400;

  @media (max-width: 630px) {
    margin-bottom: rem(8px);
  }

  margin-top: rem(8px);
}
.leftContainer {
  display: flex;
  justify-content: space-between;
  margin-right: rem(20px);
  min-width: rem(300px);
  max-width: rem(300px);

  @media (max-width: 950px) {
    margin-right: unset;
    margin-bottom: rem(8px);
  }
}

.text {
  color: #338891;
  font-family: Avenir;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 800;
  line-height: rem(18px);
  letter-spacing: -0.32px;
  width: 100%;
  margin-top: rem(8px);
  margin-bottom: rem(24px);
}

.formRow {
  display: flex;
  margin-right: rem(18px);

  @media (max-width: 950px) {
    flex-direction: column;
  }
}

.checkboxFormRow {
  display: flex;
}

.checkboxWrapper {
  margin: rem(10px) rem(20px) rem(10px) rem(20px);

  label {
    div {
      div {
        color: #338891;
        font-family: Avenir;
        font-weight: 800;
      }
    }
  }
}

.brandStoryContainer {
  background-color: white;
  border-radius: 14px;
  padding: rem(10px);
  margin: rem(20px) rem(10px);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.checkboxWrapperYes {
  margin: rem(10px) rem(20px) rem(10px) rem(20px);

  label {
    width: rem(60px);
    div {
      div {
        color: #338891;
        font-family: Avenir;
        font-weight: 800;
      }
    }
  }
}

.locationsSearch {
  label {
    width: 100%;
  }
}

.searchContainer {
  height: rem(200px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: rem(20px);
  margin-bottom: rem(10px);
  align-content: flex-start;

  .searchableCheckboxWrapper:first-child {
    width: 100%;
  }

  @media (max-width: 630px) {
    padding-left: unset;
  }

  &::-webkit-scrollbar {
    width: rem(12px);
  }

  &::-webkit-scrollbar-thumb {
    background: #338891;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #33889120;
    border-radius: 2px;
  }

  label {
    width: 100%;
  }
}

.customHorizontalLine {
  height: 1px;
  background-color: #338891;
  margin: 10px 0;
}

.tooltip {
  margin-top: rem(8px);
  // @media (max-width: 630px) {
  //   display: none;
  // }
}

.radioButtonContainer {
  display: flex;
  flex-direction: column;
  gap: rem(14px);
  margin-bottom: rem(16px);
  margin-top: rem(4px);
}

.disabledFormRow {
  opacity: 0.5;
  cursor: not-allowed;
}
