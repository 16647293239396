@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.buttonsContainer {
  position: fixed;
  left: rem(232px);
  bottom: rem(20px);
  display: flex;
  flex-direction: column;
  border-top: 1px solid #33889150;
  width: rem(730px);

  @media (max-width: 1260px) {
    width: unset;
    right: rem(280px);
  }

  @media (max-width: 990px) {
    left: rem(20px);
  }

  @media (max-width: 790px) {
    right: rem(20px);
  }
}

.buttonsWrapper {
  display: flex;
  gap: rem(30px);
  margin-top: rem(27px);
}
