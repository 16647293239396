@import '../../styles/abstract/mixins';
@import '../../styles/abstract/variables';

.container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wrapper {
  background-color: $eycrowd-color-white;
  height: auto;
  padding: rem(100px) rem(20px) rem(40px);
}
.buttonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: rem(20px);
  width: 100%;
}

.modalText {
  margin-bottom: rem(20px);
}
