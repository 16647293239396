@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.radioButtonContainer {
  display: flex;
  gap: rem(10px);
  align-items: center;
  cursor: pointer;
}

.radioButton {
  width: rem(24px);
  height: rem(24px);
  border-radius: 50%;
  border: 1px solid #338891;
  position: relative;
}

.checked {
  background-color: #338891;
  width: rem(14px);
  height: rem(14px);
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  border-radius: 50%;
}

.radioButtonText {
  color: #1e1e1e;
  font-size: rem(16px);
  font-weight: 500;
}
