@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.bannerContainer {
  padding-left: rem(275px);
  padding-right: rem(25px);
  margin-top: rem(-20px);
}

.title {
  font-size: rem(32px);
}

.subtitle {
  font-size: rem(16px);
  color: #2b2b35;
  font-weight: 500;
}

.objectiveContainer {
  position: relative;

  width: rem(210px);
  height: rem(200px);
  border-radius: rem(20px);
  border: 1px solid #e8e8e8;
  margin-top: rem(15px);
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.objectivesWrapper {
  margin-top: rem(40px);
}

.objectivesContainer {
  display: grid;
  grid-template-columns: repeat(3, rem(240px));
}

.objectiveIcon {
  width: rem(88px);
  height: rem(88px);
}

.tooltip {
  position: absolute;
  right: 4px;
  top: 8px;
}

.objectiveText {
  font-size: rem(24px);
  color: #2b2b35;
  text-align: center;
  font-weight: 700;
  padding-top: rem(18px);
  padding-right: rem(10px);
  padding-left: rem(10px);
}

.headerTitle {
  text-align: center;
  font-size: rem(20px);
  color: #2b2b35;
  font-weight: 600;
  text-decoration: underline;
}

.bottomRow {
  width: rem(705px);
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-top: rem(16px);
  margin-bottom: rem(40px);
}

.actionCard {
  width: rem(350px);
  height: rem(80px);
  border: 1px solid #e8e8e8;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: rem(18px);
  border-radius: 15px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.actionCardText {
  font-size: rem(18px);
  color: #141415;
  font-weight: 700;
  width: 75%;
}
