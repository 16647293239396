@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.rowTextContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.title {
  font-size: rem(20px);
  font-weight: 600;
  color: #000;
  color: rgba(51, 136, 145, 0.8);
}

.rowText {
  font-size: rem(15px);
  font-weight: 500;
  color: #338891;
}
