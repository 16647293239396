@import '../../../styles/abstract/mixins';
@import '../../../styles/abstract/variables';

.container {
  height: rem(230px);
  min-width: rem(205px);
  width: rem(205px);
  position: relative;

  border-radius: 30px;
  padding: rem(14px);
  cursor: pointer;
  // transform: rotateX(180deg);
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 5px solid transparent;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: rem(10px);
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: rem(10px);
  flex: 1;
  align-items: flex-end;

  p {
    font-size: rem(12px);
    font-weight: 500;
  }
}

.title {
  font-weight: 600;
  font-size: rem(16px);
  margin: rem(10px) 0;
  height: rem(45px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  &:hover .tooltipTextTitle {
    visibility: visible;
  }
}

.description {
  height: rem(60px);
  border-radius: 10px;
  background-color: #2e319010;
  padding: 4px 4px 0 4px;

  p {
    font-size: rem(14px);
    font-weight: 400;
    line-height: rem(16px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;

    &:hover .tooltipText {
      visibility: visible;
    }
  }
}

.readyColor {
  color: #2e3190;
}

.customColor {
  color: #338891;
}

.readyBackgorundColor {
  background-color: #2e3190;
}

.customBackgorundColor {
  background-color: #338891;
}

.readyBackgorundColorOpacity {
  background-color: #2e319010;
}

.customBackgorundColorOpacity {
  background-color: #33889110;
}

.readyShadow {
  -webkit-box-shadow: 4px 4px 10px 0px rgba(46, 49, 144, 1);
  -moz-box-shadow: 4px 4px 10px 0px rgba(46, 49, 144, 1);
  box-shadow: 4px 4px 10px 0px rgba(46, 49, 144, 1);
}

.customShadow {
  -webkit-box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
  -moz-box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
  box-shadow: 4px 4px 10px 0px rgba(51, 136, 145, 0.2);
}

.readySelectedContainer {
  border: 5px solid #2e3190;
}

.customSelectedContainer {
  border: 5px solid #338891;
}

.buttonContainer {
  height: rem(32px);
  width: 100%;
  border-radius: rem(70px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10px);

  p {
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;
    color: #fff;
  }
}

.tooltipText {
  visibility: hidden;
  width: rem(166px);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: rem(5px);

  left: rem(14px);
  top: rem(14px);
  /* Position the tooltip */
  position: absolute;
  z-index: 10;
}

.tooltipTextTitle {
  visibility: hidden;
  width: rem(166px);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: rem(5px);
  font-size: rem(12px);

  left: rem(14px);
  bottom: rem(58px);
  /* Position the tooltip */
  position: absolute;
  z-index: 10;
}

.tooltipInfo {
  visibility: hidden;
  width: rem(166px);
  color: black;

  border-radius: 10px;
  padding: rem(5px);
  font-size: rem(12px);

  border: 1px solid #338891;
  top: rem(14px);
  left: rem(-150px);

  /* Position the tooltip */
  position: absolute;
  background-color: white;
}

.tooltipWrapper {
  position: relative;
  &:hover .tooltipInfo {
    visibility: visible;
  }
}

.tooltipContainer {
  width: rem(20px);
  color: rgba($color: #000000, $alpha: 0.4);
}

.disabled {
  background-color: #f9f9f9;
  user-select: none;
  &.customShadow {
    -webkit-box-shadow: (4px 4px 10px rgba(14, 14, 14, 0.2));
    -moz-box-shadow: (4px 4px 10px rgba(14, 14, 14, 0.2));
    box-shadow: (4px 4px 10px rgba(14, 14, 14, 0.2));
  }
}

.disabled .customColor {
  color: #868686;
}

.disabled .customBackgorundColorOpacity {
  background-color: rgba($color: #8c8c8c, $alpha: 0.1);
}

.disabled .buttonContainer {
  background-color: rgba($color: #666666, $alpha: 0.6);
}
