/* Fonts */

$eycrowd-font-stack: 'Avenir Next', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$eycrowd-font-size-body: 16px;
$eycrowd-font-size-stack: 1rem;
$eycrowd-font-size-xxxl: 1rem * 2.75; // 44
$eycrowd-font-size-xxl: 1rem * 2.5; // 44
$eycrowd-font-size-xl: 1rem * 2; // 32
$eycrowd-font-size-lg: 1rem * 1.5; // 24
$eycrowd-font-size-1-37x: 1rem * 1.37; // 22
$eycrowd-font-size-md: 1rem * 1.25; // 20
$eycrowd-font-size-md-2: 1rem * 1.125; // 14
$eycrowd-font-size-md-3: 1rem * 1; // 14
$eycrowd-font-size-sm: 1rem * 0.875; // 14
$eycrowd-font-size-xs: 1rem * 0.75; // 12
$eycrowd-font-size-xxs: 1rem * 0.625; // 10

$eycrowd-font-line-height-stack: 1.4;
$eycrowd-font-line-height-sm: 1.3;
$eycrowd-font-line-height-xs: 1.2;
$eycrowd-font-line-height-xxs: 1.1;

/* Colors */

$eycrowd-color-white: #ffffff;
$eycrowd-color-white-01: #e4e4e4;
$eycrowd-color-white-02: #e3e3e3;
$eycrowd-color-black: #000000;
$eycrowd-color-black-01: #121316;
$eycrowd-color-black-02: #131415;
$eycrowd-color-black-03: #1c1c1d;
$eycrowd-color-transparent: rgba(0, 0, 0, 0);

$eycrowd-color-gray: #3c3c3c;
$eycrowd-color-gray-01: #2b2b35;
$eycrowd-color-gray-02: #747474;
$eycrowd-color-gray-03: #d8d8d8;
$eycrowd-color-light-gray: #9fa9b5;
$eycrowd-color-light-gray-01: #b8b8b8;
$eycrowd-color-light-gray-02: #f8f8f8;
$eycrowd-color-light-gray-03: #fafafa;
$eycrowd-color-red: #fd5f55;
$eycrowd-color-red-01: #e24d44;
$eycrowd-color-red-02: #de4a41;
$eycrowd-color-red-03: #ff4f4c;
$eycrowd-color-red-04: #f05f58;
$eycrowd-color-red-dark: #c5332b33;
$eycrowd-color-green: #30ba00;
$eycrowd-color-green-02: #269a45;

$eycrowd-color-primary: #ffd700;
$eycrowd-color-primary-01: #fcc355;
$eycrowd-color-primary-02: #ffdf33;
$eycrowd-color-yellow: #ee9800;
$eycrowd-color-blue: #1da1f3;
$eycrowd-color-blue-01: #519bff;
$eycrowd-color-blue-02: #3694b7;
$eycrowd-color-blue-dark: #141415;
$eycrowd-color-warning: #bd3547;
$eycrowd-color-warning-input-bg: rgba(189, 53, 71, 0.1);
$eycrowd-color-warning-autofill: #330c12;

$eycrowd-color-text: #1d1f21;

$eycrowd-color-app-bg: $eycrowd-color-white;

$eycrowd-gradient-main: linear-gradient(
  134.9deg,
  $eycrowd-color-red,
  $eycrowd-color-red-02 100%
);

$eycrowd-gradient-green: linear-gradient(
  134.9deg,
  $eycrowd-color-green,
  $eycrowd-color-green-02 100%
);

$eycrowd-border-active: 1px solid $eycrowd-color-red;
$eycrowd-border-gray-01: 1px solid rgba(#131415, 0.1);

/* Radius */

$eycrowd-radius-round: 50%;
$eycrowd-radius-pill: 100px;
$eycrowd-radius: 4px;
$eycrowd-radius-xs: 2px;
$eycrowd-radius-sm-2: 8px;
$eycrowd-radius-sm: 10px;
$eycrowd-radius-md: 18px;
$eycrowd-radius-lg: 25px;
$eycrowd-radius-xl: 40px;

/* Spacings */

$eycrowd-gutter: 1rem;
$eycrowd-gutter-xs: $eycrowd-gutter * 0.25;
$eycrowd-gutter-sm: $eycrowd-gutter * 0.5;
$eycrowd-gutter-075: $eycrowd-gutter * 0.75;
$eycrowd-gutter-xl: $eycrowd-gutter * 1.5;
$eycrowd-gutter-2g: $eycrowd-gutter * 2;
$eycrowd-gutter-3l: $eycrowd-gutter * 3;
$eycrowd-gutter-4xl: $eycrowd-gutter * 4;

/* Sizes */

$eycrowd-size-main-container: 84rem; // 1344px
$eycrowd-size-input-height: 3.375rem; // 54px
$eycrowd-size-button-height: 3.75rem; // 60px
$eycrowd-size-avatar: 4.0625rem; // 65px
$eycrowd-size-avatar-sm: 3.5rem; // 56px
$eycrowd-size-header: 6rem;
$eycrowd-size-safari-layout-fix: 1px;

$eycrowd-size-icon-sm: 1rem;
$eycrowd-size-icon: 1.5rem;
$eycrowd-size-icon-md: 1.75rem;
$eycrowd-size-icon-lg: 2rem;
$eycrowd-size-icon-xl: 2.75rem;

/* Screens */

$eycrowd-screen-full-hd: 120rem; // 1920px
$eycrowd-screen-laptop-lg: 90rem; // 1440px
$eycrowd-screen-laptop: 80rem; // 1280px
$eycrowd-screen-laptop-md: 75rem; // 1200px
$eycrowd-screen-laptop-sm: 64rem; // 1024px
$eycrowd-screen-tablet-landscape: 61.93rem; // 991px
$eycrowd-screen-tablet-portrait: 48rem; // 768px
$eycrowd-screen-mobile-landscape: 46.25rem; // 740px
$eycrowd-screen-mobile-portrait-lg: 30rem; // 480px
$eycrowd-screen-mobile-portrait: 23.4375rem; // 375px
$eycrowd-screen-mobile-sm-portrait: 20rem; // 320px

/* Z indexes */
$eycrowd-z-index-package-section: -1;
$eycrowd-z-index-01: 1;
$eycrowd-z-index-input: 2;
$eycrowd-z-index-error: 3;
$eycrowd-z-index-date-time-pickers: 4;
$eycrowd-z-index-header: 5;

/* CSS Variables */

// transition
$eycrowd-transition: all 0.3s ease;

// shadows
$eycrowd-side-shadow: 0 12px 24px 4px rgba(0, 0, 0, 0.1);
$eycrowd-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$eycrowd-shadow-01: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
$eycrowd-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
$eycrowd-box-shadow-big: 0 24px 48px -12px rgba(0, 0, 0, 0.1);
$eycrowd-box-shadow-big-01: 0 24px 48px -24px rgba(0, 0, 0, 0.27);
$eycrowd-shadow-red: 0 24px 48px -12px $eycrowd-color-red;
$eycrowd-shadow-dark-red: 0 12px 16px -2px #c5332b33;
$eycrowd-shadow-yellow: 0 24px 48px -12px $eycrowd-color-primary;
$eycrowd-shadow-blue: 0 24px 48px -12px $eycrowd-color-blue;
